/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import schmittsKatzeOgImage from "../../images/schmittskatzewebsite.png"

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const seoRichSnippet = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Schmitts Katze",
    alternateName: "Schmitts Katze",
    url: "https://schmitts-katze.de/",
    logo: "",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+49 89 41115555",
      contactType: "sales",
      areaServed: "DE",
      availableLanguage: ["en", "German"],
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://schmitts-katze.de`,
        },
        {
          property: `og:image`,
          content: `https://schmitts-katze.de${schmittsKatzeOgImage}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `radlpmk7ma49ebz6gdukf9hhltm572`,
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(seoRichSnippet)}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
