import { Heading1 } from "@elements/typography"
import React, { useEffect } from "react"
import Seo from "@root/seo"
import WpCasesArchive from "@modules/WpCasesArchive"
import tw, { styled } from "twin.macro"
import { useStateContext } from "@context/stateContext"
import { motion } from "framer-motion"
import { graphql } from "gatsby"
import WpPostsArchive from "../components/modules/WpPostsArchive"

const Header = styled(motion.section)`
  ${tw`flex items-center justify-center w-full p-12 pt-32 text-center lg:pt-64`}
`

const BlogPage = () => {
  const stateContext = useStateContext()

  useEffect(() => {
    stateContext.dispatch({ action: "SET_MENUE_LIGHT" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Seo title="Blog" />
      <Header
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
      >
        <Heading1>Blog</Heading1>
      </Header>
      <motion.div initial={{ y: 30, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0.17, -0.05, 0.26, 0.67] }}>
        <WpPostsArchive />
      </motion.div>
    </>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query WordPressPostArchive {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`

// export const pageQuery = graphql`
//   query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
//     allWpPost(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
//       nodes {
//         excerpt
//         uri
//         date(formatString: "MMMM DD, YYYY")
//         title
//         excerpt
//       }
//     }
//   }
// `
